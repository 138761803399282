import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../config/firebaseApp';
import { toast } from 'react-toastify';
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import {
  IJobMetaInfo,
  IJobInfo,
  IEmploymentIntakeInfo,
  IConfirmClientEmployment,
  ITiming,
  IPreHireReq,
  IJobReportMetaInfo,
} from '../store/employmentModule/employmentModal';
import { userAccountStatus } from '../utils/userUtils';
import { getMultipleClientByIds } from './client.service';
import { getMultipleUserInfoByUserIds, getUserInfoById } from './user.service';
import moment from 'moment-timezone';
import dayjs, { Dayjs } from 'dayjs';
import { ISortModel } from '../utils/common';

export const addNewJobOpportunity = async (jobInfo: IJobInfo) => {
  try {
    if (!jobInfo) {
      throw new Error();
    }
    const response: any = await httpsCallable(functions, 'createJobOpportunity')(jobInfo);

    if (response.data.success) {
      toast.success('New Job Opportunity Created Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Creating a Job Opportunity!');
  }
};

export const editJobOpportunity = async (jobInfo: IJobInfo) => {
  try {
    if (!jobInfo) {
      throw new Error();
    }
    const collectionDocRef = doc(db, 'jobOpportunities', jobInfo.id);
    const resultDoc = await getDoc(collectionDocRef);
    if (resultDoc.exists()) {
      const response: any = await httpsCallable(functions, 'editJobOpportunity')(jobInfo);

      if (response.data.success) {
        toast.success('Job Opportunity Updated Successfully!');
      }
    }
  } catch (err: any) {
    toast.error('Error Updating a Job Opportunity!');
  }
};

export const deleteJobOpportunity = async (jobId: string) => {
  try {
    const response: any = await httpsCallable(functions, 'deleteJobOpportunity')({ id: jobId });
    if (response.data.success) {
      toast.success('Job Opportunity Deleted Successfully!');
    }
  } catch (error: any) {
    toast.error('Error Deleting Job Opportunity!');
  }
};

const findFarthestDate = (availableTimings: ITiming[]) => {
  let farthestDate = '';

  for (const item of availableTimings) {
    const currentTiming = dayjs(item.timing);

    if (!farthestDate || currentTiming.valueOf() > dayjs(farthestDate).valueOf()) {
      farthestDate = currentTiming.format('YYYY-MM-DD hh:mm A');
    }
  }
  return farthestDate;
};

export const getFilteredJobs = (jobList: IJobInfo[], activeIds: number[]) => {
  const getDate = moment.tz('America/New_York');
  const currentDate = getDate.format('YYYY-MM-DD hh:mm A');
  const filteredJobs: IJobInfo[] = [];

  jobList.forEach((data) => {
    const dateTime = moment(findFarthestDate(data.availableTimings))
      .tz('America/New_York')
      .format('YYYY-MM-DD hh:mm A');
    const active = activeIds.includes(1);
    const finished = activeIds.includes(2);

    if (active && finished) {
      filteredJobs.push(data);
    } else if (active) {
      if (currentDate < dateTime) {
        filteredJobs.push(data);
      }
    } else if (finished) {
      if (currentDate > dateTime) {
        filteredJobs.push(data);
      }
    } else {
      filteredJobs.push(data);
    }
  });
  return filteredJobs;
};

export const getJobList = async (page: number, activeIds: number[], sortModel: ISortModel[], callback: Function) => {
  const pageSize = 10;
  const offset = (page - 1) * pageSize;
  let result: IJobMetaInfo = {
    totalRecords: 0,
    currentPage: page,
    jobOpportunities: [],
    totalPages: 0,
  };
  try {
    const collectionRef = collection(db, 'jobOpportunities');
    const unsubscribe = onSnapshot(collectionRef, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const jobOpportunities: any = [];
        const employerIds: string[] = [];
        querySnapshot.forEach(async (doc) => {
          employerIds.push(doc.data().employer);
          jobOpportunities.push({ id: doc.id, ...doc.data() });
        });
        const employersInfo: any[] = await getMultipleUserInfoByUserIds(employerIds);
        const updatedJobOpportunities = jobOpportunities.map((job: any) => ({
          ...job,
          employer: employersInfo.find((employer: any) => employer.id === job.employer)?.organization || '',
        }));
        const filteredJobs = getFilteredJobs(updatedJobOpportunities, activeIds);

        if (sortModel.length > 0) {
          const field = sortModel[0].field;
          const sort = sortModel[0].sort;

          if (field === 'title') {
            filteredJobs.sort((a: any, b: any) => {
              if (sort === 'asc') {
                return a.title.localeCompare(b.title);
              } else if (sort === 'desc') {
                return b.title.localeCompare(a.title);
              }
            });
          } else if (field === 'employer') {
            filteredJobs.sort((a: any, b: any) => {
              if (sort === 'asc') {
                return a.employer.localeCompare(b.employer);
              } else if (sort === 'desc') {
                return b.employer.localeCompare(a.employer);
              }
            });
          } else if (field === 'type') {
            filteredJobs.sort((a: any, b: any) => {
              if (sort === 'asc') {
                return a.type.localeCompare(b.type);
              } else if (sort === 'desc') {
                return b.type.localeCompare(a.type);
              }
            });
          } else if (field === 'salary') {
            filteredJobs.sort((a: IJobInfo, b: IJobInfo) => {
              const salaryA = Number(a.salary.replace(/\D/g, ''));
              const salaryB = Number(b.salary.replace(/\D/g, ''));

              if (isNaN(salaryA) || isNaN(salaryB)) {
                return 0;
              }

              if (sort === 'asc') {
                return salaryA - salaryB;
              } else if (sort === 'desc') {
                return salaryB - salaryA;
              }

              return 0;
            });
          } else if (field === 'numApplicants') {
            filteredJobs.sort((a: any, b: any) => {
              if (a.numApplicants === undefined && b.numApplicants === undefined) {
                return 0;
              } else if (a.numApplicants === undefined) {
                return 1;
              } else if (b.numApplicants === undefined) {
                return -1;
              } else {
                if (sort === 'asc') {
                  return a.numApplicants - b.numApplicants;
                } else if (sort === 'desc') {
                  return b.numApplicants - a.numApplicants;
                }
              }
              return 0;
            });
          } else if (field === 'createdAt') {
            filteredJobs.sort((a: any, b: any) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              if (sort === 'asc') {
                return dateA.getTime() - dateB.getTime();
              } else if (sort === 'desc') {
                return dateB.getTime() - dateA.getTime();
              }
              return 0;
            });
          }
        } else {
          filteredJobs.sort((a: any, b: any) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB.getTime() - dateA.getTime();
          });
        }
        const totalPages = Math.ceil(filteredJobs.length / pageSize);
        const limitedJobs = filteredJobs.slice(offset, offset + pageSize);
        result = {
          totalRecords: filteredJobs.length,
          currentPage: page,
          jobOpportunities: limitedJobs,
          totalPages: totalPages,
        };
        callback(result);
      } else {
        callback(result);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    toast.error('Error Retrieving Job Data!');
    return result;
  }
};

export const getJobTitleById = async (jobId: string, callback: Function) => {
  try {
    if (jobId) {
      const colRef = doc(db, 'jobOpportunities', jobId);
      const unsubscribe = onSnapshot(colRef, (snapshot) => {
        if (snapshot.exists()) {
          callback(snapshot.data().title);
        } else {
          callback('');
        }
      });
      return unsubscribe;
    }
    callback('');
    return () => {};
  } catch (error: any) {
    toast.error('Error Retrieving Job Title!');
    return {};
  }
};

export const getJobById = async (jobId: string, callback: Function) => {
  try {
    if (jobId) {
      const colRef = doc(db, 'jobOpportunities', jobId);
      const unsubscribe = onSnapshot(colRef, async (snapshot) => {
        if (snapshot.exists()) {
          const employerInfo: any = await getUserInfoById(snapshot.data().employer);
          const employer = employerInfo.organization;
          const jobData = { id: snapshot.id, ...snapshot.data(), employer: employer };
          callback(jobData);
        } else {
          callback({});
        }
      });
      return unsubscribe;
    }
    callback({});
    return () => {};
  } catch (error: any) {
    toast.error('Error Subscribing to Job Data!');
    return () => {};
  }
};

export const getMultipleJobsByIds = async (jobIds: string[]) => {
  try {
    if (jobIds.length) {
      const jobRef = collection(db, 'jobOpportunities');
      const querySnapshot = await getDocs(query(jobRef));
      const documents = querySnapshot.docs.filter((doc) => jobIds.includes(doc.id));
      const jobList: any = [];

      documents.forEach((doc) => {
        jobList.push({ id: doc.id, ...doc.data() });
      });
      const employerIds: string[] = jobList.map((job: IJobInfo) => {
        return job.employer;
      });
      const employersInfo: any[] = await getMultipleUserInfoByUserIds(employerIds);
      const updatedJobOpportunities = jobList.map((job: any) => ({
        ...job,
        employer: employersInfo.find((employer: any) => employer.id === job.employer).organization,
      }));

      return updatedJobOpportunities;
    }
    return [];
  } catch (err: any) {
    toast.error('Error Retrieving Job Opportunities!');
    return [];
  }
};

export const searchJobOpportunity = async (searchQuery: string, page: number, activeIds: number[]) => {
  try {
    if (!searchQuery) {
      throw new Error();
    }

    const response: any = await httpsCallable(functions, 'searchJobOpportunityByTitle')({ query: searchQuery });

    const jobData = response.data.data;
    const jobIds: any = [];
    let jobList = [];

    if (jobData.length > 0) {
      for (const data of jobData) {
        jobIds.push(data.id);
      }

      jobList = await getMultipleJobsByIds(jobIds);

      const filteredJobs = getFilteredJobs(jobList, activeIds);
      const pageSize = 10;
      const offset = (page - 1) * pageSize;
      const totalPages = Math.ceil(filteredJobs.length / pageSize);
      const limitedJobs = filteredJobs.slice(offset, offset + pageSize);
      const result: IJobMetaInfo = {
        totalRecords: filteredJobs.length,
        currentPage: page,
        jobOpportunities: limitedJobs,
        totalPages: totalPages,
      };
      return result;
    } else {
      return {
        totalRecords: 0,
        currentPage: page,
        jobOpportunities: [],
        totalPages: 0,
      };
    }
  } catch (error) {
    toast.error('Error Searching Job Opportunity!');
  }
};

export const addEmploymentIntakeInfo = async (payload: IEmploymentIntakeInfo) => {
  try {
    if (!payload) {
      throw new Error();
    }
    const response: any = await httpsCallable(functions, 'createClientsEmploymentInfo')(payload);

    if (response.data.success) {
      toast.success('Client Employment Intake Info Created Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Creating Client Employment Intake Info!');
  }
};

export const getEmploymentIntakeInfoById = (id: string, callback: Function) => {
  try {
    if (id) {
      const collectionRef = doc(db, 'clientEmploymentInfo', id);

      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        if (snapshot.exists()) {
          const employmentIntakeData = { id: snapshot.id, ...snapshot.data() };
          callback(employmentIntakeData);
        } else {
          callback({});
        }
      });

      return unsubscribe;
    }
    return () => {}; // Return a dummy function if id is not provided
  } catch (error: any) {
    toast.error('Error Retrieving Employment Intake Data!');
    return () => {}; // Return a dummy function if an error occurs
  }
};

export const fetchEligibleClientsForJobOpportunity = async (jobId: string, callback: Function) => {
  try {
    const collectionRef = collection(db, 'clients');
    const unsubscribe = onSnapshot(collectionRef, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const clientIds: string[] = [];
        querySnapshot.docs.forEach((doc: any) => {
          let isJobIdExists = false;
          if (doc.data().scheduledJobInterview) {
            isJobIdExists = doc.data().scheduledJobInterview.some((interview: any) => interview.jobId === jobId);
          }
          if (
            doc.data().account !== userAccountStatus.DELETED &&
            doc.data().workshopAttendance &&
            doc.data().completedWorkshops &&
            !isJobIdExists &&
            !doc.data().employment
          ) {
            clientIds.push(doc.id);
          }
        });
        const clientList = await getMultipleClientByIds(clientIds);
        callback(clientList);
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    throw new Error('Error While Fetching Client Data Eligible For Job Opportunity');
  }
};

export const scheduleClientJobInterview = async (
  clientIds: string[],
  jobId: string,
  scheduledBy: string,
  interviewTimings?: string[]
) => {
  try {
    const payload = {
      ids: clientIds,
      jobId: jobId,
      scheduledBy: scheduledBy,
      interviewTimings,
    };
    const response = await httpsCallable(functions, 'scheduleClientJobInterview')(payload);
    return response.data;
  } catch (error) {
    toast.error('Error While Scheduling Client Job Interview!');
  }
};

export const confirmClientEmployment = async (payload: IConfirmClientEmployment) => {
  try {
    const response: any = await httpsCallable(functions, 'confirmClientEmployment')(payload);
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Confirming Client Employment!');
    }
  } catch (error) {
    toast.error('Error While Confirming Client Employment!');
  }
};

export const fetchJobsWithClientInterviews = async (clientId: string, callback: Function) => {
  try {
    const collectionRef = doc(db, 'clients', clientId);
    const unsubscribe = onSnapshot(collectionRef, async (snapshot) => {
      if (snapshot.exists()) {
        const jobIds: string[] = [];
        if (snapshot.data().scheduledJobInterview) {
          snapshot.data().scheduledJobInterview.forEach((item: any) => {
            jobIds.push(item.jobId);
          });
        }
        if (jobIds.length > 0) {
          const jobList = await getMultipleJobsByIds(jobIds);
          callback(jobList);
        } else {
          callback([]);
        }
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    throw new Error('Error While Fetching Job Data in which Job Interview is Scheduled!');
  }
};

export const fetchClientsInvitedToJob = async (jobId: string, callback: Function) => {
  try {
    const collectionRef = collection(db, 'clients');
    const unsubscribe = onSnapshot(collectionRef, async (querySnapshot) => {
      const clientIds: string[] = [];

      if (!querySnapshot.empty) {
        querySnapshot.docs.forEach((doc: any) => {
          if (doc.data().scheduledJobInterview && !doc.data().employment) {
            const isJobIdExists = doc.data().scheduledJobInterview.some((interview: any) => interview.jobId === jobId);
            if (isJobIdExists) {
              clientIds.push(doc.id);
            }
          }
        });

        const clientList = await getMultipleClientByIds(clientIds);
        callback(clientList);
      } else {
        callback([]);
      }
    });

    return unsubscribe;
  } catch (err: any) {
    throw new Error('Error While Fetching Clients Invited To Job!');
  }
};

export const fetchClientsHiredInJob = async (jobId: string, callback: Function) => {
  try {
    const collectionRef = collection(db, 'clients');
    const unsubscribe = onSnapshot(collectionRef, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const clientIds: string[] = [];
        querySnapshot.docs.forEach((doc: any) => {
          if (doc.data().employment && doc.data().employment.jobId === jobId) {
            clientIds.push(doc.id);
          }
        });
        const clientList = await getMultipleClientByIds(clientIds);
        callback(clientList);
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    throw new Error('Error While Fetching Clients Invited To Job!');
  }
};

export const fetchAllJobs = async (clientId: string, callback: Function) => {
  try {
    const clientRef = doc(db, 'clients', clientId);
    const clientDoc = await getDoc(clientRef);

    if (clientDoc.exists()) {
      const clientData = clientDoc.data();
      const scheduledJobInterview = clientData?.scheduledJobInterview || [];

      const colRef = collection(db, 'jobOpportunities');

      const unsubscribe = onSnapshot(colRef, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const jobList: any[] = [];
          querySnapshot.forEach((doc) => {
            const isJobInScheduledInterview = scheduledJobInterview.some(
              (interview: any) => interview.jobId === doc.id
            );

            if (!isJobInScheduledInterview) {
              jobList.push({ id: doc.id, ...doc.data() });
            }
          });

          callback(jobList);
        } else {
          callback([]);
        }
      });
      return unsubscribe;
    } else {
      callback([]);
      return () => {};
    }
  } catch (err: any) {
    console.log(err.message);
    return () => {};
  }
};

export const getJobByJobId = async (jobId: string) => {
  try {
    if (jobId) {
      const colRef = doc(db, 'jobOpportunities', jobId);
      const snapshot = await getDoc(colRef);
      if (snapshot.exists()) {
        const employerInfo: any = await getUserInfoById(snapshot.data().employer);
        const employer = employerInfo.organization;
        const jobData = { id: snapshot.id, ...snapshot.data(), employer: employer };
        return jobData;
      } else {
        return {};
      }
    }
    return {};
  } catch (error: any) {
    toast.error('Error Subscribing to Job Data!');
  }
};

export const getJobOpportinities = async () => {
  try {
    const collectionRef = collection(db, 'jobOpportunities');
    const snapshots = await getDocs(collectionRef);
    const jobList: any[] = snapshots.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });

    return jobList;
  } catch (error) {
    toast.error('Error Fetching Job Opportunities!');
    return [];
  }
};

export const isEmployerAttachedToJob = async (emoloyerId: string) => {
  try {
    if (!emoloyerId) {
      throw new Error();
    }

    const collectionRef = collection(db, 'jobOpportunities');
    const q = query(collectionRef, where('emoloyerId', '==', emoloyerId));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty ? true : false;
  } catch (err: any) {
    toast.error('Error Retrieving Data!');
  }
};

export const addNewInterviewTimingInJob = async (jobId: string, newTiming: string) => {
  try {
    const payload = {
      jobId,
      newTiming,
    };
    await httpsCallable(functions, 'addNewInterviewTiming')(payload);
    return;
  } catch (error) {
    toast.error('Error While Scheduling Client Job Interview!');
  }
};

export const rescheduleJobInterview = async (clientId: string, jobId: string, rescheduledTimings: string[]) => {
  try {
    const payload = {
      clientId,
      jobId,
      rescheduledTimings,
    };
    await httpsCallable(functions, 'rescheduleJobInterview')(payload);
    return;
  } catch (error) {
    toast.error('Error While Rescheduling Client Job Interview!');
  }
};

export const setClientJobOfferStatus = async (clientId: string, jobId: string, jobOfferStatus: string) => {
  try {
    const payload = {
      clientId,
      jobId,
      jobOfferStatus,
    };
    await httpsCallable(functions, 'setClientJobOfferStatus')(payload);
    return;
  } catch (error) {
    toast.error('Error While Setting Client Job Offer Status!');
  }
};

export const setClientCompleteAndHired = async (
  clientId: string,
  jobId: string,
  preHireRequirements: IPreHireReq[],
  isCostCoverdByPincc: string,
  startAt: string
) => {
  try {
    const payload = {
      clientId,
      jobId,
      preHireRequirements,
      isCostCoverdByPincc,
      startAt,
    };
    const response: any = await httpsCallable(functions, 'setClientCompleteAndHired')(payload);
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Setting Client Completed and Hired!');
    }
    return;
  } catch (error) {
    toast.error('Error While Setting Client Completed and Hired!');
  }
};

export const removeClientsOfferAcceptedEntry = async (clientId: string, jobId: string) => {
  try {
    const payload = {
      clientId,
      jobId,
    };
    const response: any = await httpsCallable(functions, 'removeClientsOfferAcceptedEntry')(payload);
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Removing Clients Offer Accepted Entry!');
    }
    return;
  } catch (error) {
    toast.error('Error While Removing Clients Offer Accepted Entry!');
  }
};

export const getEmployerNamesByIds = async (employerIds: string[]) => {
  try {
    if (employerIds.length > 0) {
      const staffRef = collection(db, 'staff');
      const querySnapshot = await getDocs(query(staffRef));
      const documents = querySnapshot.docs.filter((doc) => employerIds.includes(doc.id));

      const nameObject = documents.reduce((acc, doc) => {
        acc[doc.id] = doc.data().organization || 'Unknown Organization';
        return acc;
      }, {} as { [key: string]: string });

      return nameObject;
    } else {
      return {};
    }
  } catch (error) {
    toast.error('Error While Fetching Employer Names!');
    return {};
  }
};

export const getJobOpportinitiesWithinRange = async (startDate: Dayjs, endDate: Dayjs, page: number) => {
  try {
    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();

    const jobOpportunitiesRef = collection(db, 'jobOpportunities');

    const jobOpportunitiesSnapshot = await getDocs(jobOpportunitiesRef);
    let jobOpportunities = [];

    for (const jobDoc of jobOpportunitiesSnapshot.docs) {
      const jobData = jobDoc.data();
      const createdAtString = jobData.createdAt;

      if (createdAtString >= startDateString && createdAtString <= endDateString) {
        const employerId = jobData.employer;

        if (employerId) {
          const employerDocRef = doc(db, 'staff', employerId);
          const employerDoc = await getDoc(employerDocRef);

          if (employerDoc.exists()) {
            const employerData = employerDoc.data();
            jobOpportunities.push({
              id: jobDoc.id,
              type: jobData.type,
              title: jobData.title,
              createdAt: jobData.createdAt,
              employerName: employerData.organization,
            });
          }
        }
      }
    }

    const pageSize = 10;
    const offset = (page - 1) * pageSize;
    const totalPages = Math.ceil(jobOpportunities.length / pageSize);
    const limitedJobs = jobOpportunities.slice(offset, offset + pageSize);
    const result: IJobReportMetaInfo = {
      totalRecords: jobOpportunities.length,
      currentPage: page,
      jobOpportunities: limitedJobs,
      totalPages: totalPages,
    };

    return result;
  } catch (error) {
    console.error('Error retrieving job opportunities:', error);
    throw new Error('Unable to retrieve job opportunities within the given range');
  }
};

export const getJobsByEmployerId = async (employerId: string) => {
  try {
    const jobsRef = collection(db, 'jobOpportunities');

    const q = query(jobsRef, where('employer', '==', employerId));

    const querySnapshot = await getDocs(q);

    const jobs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return jobs;
  } catch (error) {
    console.error('Error fetching job opportunities:', error);
    throw new Error('Failed to fetch job opportunities');
  }
};

export const getMultipleJobByIdsForReport = async (jobIds: string[]) => {
  try {
    if (jobIds.length) {
      const jobRef = collection(db, 'jobOpportunities');
      const querySnapshot = await getDocs(query(jobRef));
      const documents = querySnapshot.docs.filter((doc) => jobIds.includes(doc.id));
      const jobList: any = [];

      documents.forEach((doc) => {
        jobList.push({ id: doc.id, ...doc.data() });
      });

      return jobList;
    }
    return [];
  } catch (err: any) {
    toast.error('Error Retrieving Job Opportunities!');
    return [];
  }
};
