import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { doc } from 'firebase/firestore';
import { db, functions } from '../config/firebaseApp';
import {
  InfoSessionModel,
  InfoSessionEditModel,
  IAddPCToAttendanceRoster,
} from '../store/infoSession/infoSessionModel';
import { httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { getMultipleClientByIds } from './client.service';
import { userAccountStatus } from '../utils/userUtils';
import { getUserInfoById } from './user.service';
import { convertTimeStampToDate } from '../utils/dateTime';
import { ISortModel } from '../utils/common';

export interface IInfoSessionsMetaInfo {
  totalRecords: number;
  currentPage: number;
  infoSessions: Array<Object>;
  totalPages: number;
}

export const addNewInfoSession = async (infoSession: InfoSessionModel) => {
  try {
    if (!infoSession) {
      throw new Error();
    }
    const response: any = await httpsCallable(functions, 'createInfoSession')(infoSession);

    if (response.data.success) {
      toast.success('New Info-Session Created Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Creating an Info-Session!');
  }
};

export const fetchInfoSessionList = async (callback: Function) => {
  const workshops: any = [];
  try {
    const colRef = collection(db, 'infoSessions');
    const unsubscribe = onSnapshot(colRef, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const infoSessions: any[] = [];

        querySnapshot.forEach((doc) => {
          if (!doc.data().isAttendanceMarked) {
            infoSessions.push({ id: doc.id, ...doc.data() });
          }
        });

        callback(infoSessions);
      } else {
        callback([]);
      }
    });

    return unsubscribe;
  } catch (err: any) {
    console.log(err.message);
  }
  return workshops;
};

export const getFilteredInfoSession = (infoSessionList: InfoSessionEditModel[], activeIds: number[]) => {
  const getDate = moment.tz('America/New_York');
  const currentDate = getDate.format('YYYY-MM-DD hh:mm A');
  const filteredInfoSession: InfoSessionEditModel[] = [];

  infoSessionList.forEach((data) => {
    const dateTime = moment(data.dateTime).tz('America/New_York').format('YYYY-MM-DD hh:mm A');
    const active = activeIds.includes(1);
    const finished = activeIds.includes(2);

    if (active && finished) {
      filteredInfoSession.push(data);
    } else if (active) {
      if (currentDate < dateTime) {
        filteredInfoSession.push(data);
      }
    } else if (finished) {
      if (currentDate > dateTime) {
        filteredInfoSession.push(data);
      }
    } else {
      filteredInfoSession.push(data);
    }
  });

  return filteredInfoSession;
};

export const getInfoSessionsByQuery = (
  page: number,
  sortModel: ISortModel[],
  callback: Function,
  activeIds?: number[]
) => {
  try {
    const infoSessionRef = collection(db, 'infoSessions');
    const pageSize = 10;
    const offset = (page - 1) * pageSize;
    const currentDateTime = new Date();
    currentDateTime.setHours(0, 0, 0, 0);

    const fourteenDaysFromNow = new Date(currentDateTime.getTime() + 14 * 24 * 60 * 60 * 1000);
    fourteenDaysFromNow.setHours(23, 59, 59, 999);

    const unsubscribe = onSnapshot(infoSessionRef, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const infoSessionList: Array<InfoSessionEditModel> = [];
        querySnapshot.forEach((doc) => {
          const data = { id: doc.id, ...doc.data() } as InfoSessionEditModel;
          infoSessionList.push(data);
        });
        const upcomingSessions = infoSessionList?.filter((item: any) => {
          const itemDateTime = new Date(item.dateTime);
          itemDateTime.setHours(0, 0, 0, 0);

          return itemDateTime >= currentDateTime && itemDateTime <= fourteenDaysFromNow;
        });
        const filteredInfoSession = activeIds
          ? getFilteredInfoSession(infoSessionList, activeIds || [])
          : [...upcomingSessions];

        if (sortModel.length > 0) {
          const field = sortModel[0].field;
          const sort = sortModel[0].sort;

          if (field === 'hra') {
            filteredInfoSession.sort((a: any, b: any) => {
              if ('hra' in a && 'hra' in b) {
                if (sort === 'asc') {
                  return a.hra.localeCompare(b.hra);
                } else if (sort === 'desc') {
                  return b.hra.localeCompare(a.hra);
                }
              } else if ('hra' in a) {
                return -1;
              } else if ('hra' in b) {
                return 1;
              }
              return 0;
            });
          } else if (field === 'location') {
            filteredInfoSession.sort((a: any, b: any) => {
              if (!a.location && !b.location) {
                return 0;
              } else if (!a.location) {
                return 1;
              } else if (!b.location) {
                return -1;
              } else {
                if (sort === 'asc') {
                  return a.location.localeCompare(b.location);
                } else if (sort === 'desc') {
                  return b.location.localeCompare(a.location);
                }
              }
            });
          } else if (field === 'title') {
            filteredInfoSession.sort((a: any, b: any) => {
              if (sort === 'asc') {
                return a.title.localeCompare(b.title);
              } else if (sort === 'desc') {
                return b.title.localeCompare(a.title);
              }
            });
          } else if (field === 'dateTime') {
            filteredInfoSession.sort((a: InfoSessionEditModel, b: InfoSessionEditModel) => {
              const dateA = moment(a.dateTime).tz('America/New_York');
              const dateB = moment(b.dateTime).tz('America/New_York');

              if (sort === 'asc') {
                return dateA.valueOf() - dateB.valueOf();
              } else if (sort === 'desc') {
                return dateB.valueOf() - dateA.valueOf();
              }
              return 0;
            });
          } else if (field === 'sessionType') {
            filteredInfoSession.sort((a: any, b: any) => {
              if (!a.sessionType && !b.sessionType) {
                return 0;
              } else if (!a.sessionType) {
                return 1;
              } else if (!b.sessionType) {
                return -1;
              } else {
                if (sort === 'asc') {
                  return a.sessionType.localeCompare(b.sessionType);
                } else if (sort === 'desc') {
                  return b.sessionType.localeCompare(a.sessionType);
                }
              }
            });
          } else if (field === 'numClients') {
            filteredInfoSession.sort((a: InfoSessionEditModel, b: InfoSessionEditModel) => {
              if (a.numClients === undefined && b.numClients === undefined) {
                return 0;
              } else if (a.numClients === undefined) {
                return 1;
              } else if (b.numClients === undefined) {
                return -1;
              } else {
                if (sort === 'asc') {
                  return a.numClients - b.numClients;
                } else if (sort === 'desc') {
                  return b.numClients - a.numClients;
                }
              }
              return 0;
            });
          }
        } else {
          filteredInfoSession.sort((a, b) => {
            const dateA = a.createdAt ? convertTimeStampToDate(a.createdAt).toDate() : null;
            const dateB = b.createdAt ? convertTimeStampToDate(b.createdAt).toDate() : null;

            if (dateA && dateB) {
              return dateB.getTime() - dateA.getTime();
            } else if (dateB) {
              return 1; // Place documents without 'createdAt' field after those with 'createdAt'
            } else if (dateA) {
              return -1; // Place documents without 'createdAt' field before those with 'createdAt'
            } else {
              return 0; // Both documents don't have 'createdAt' field, no preference in order
            }
          });
        }

        const totalPages = Math.ceil(filteredInfoSession.length / pageSize);
        const limitedInfoSessions = filteredInfoSession.slice(offset, offset + pageSize);
        const result: IInfoSessionsMetaInfo = {
          totalRecords: filteredInfoSession.length,
          currentPage: page,
          infoSessions: limitedInfoSessions,
          totalPages: totalPages,
        };

        callback(result);
      } else {
        toast.error('No available Info Sessions!');
      }
    });

    return unsubscribe;
  } catch (err: any) {
    toast.error('Error Retrieving Info Session List!');
    return () => {};
  }
};

export const deleteInfoSession = async (id: any) => {
  const response = await httpsCallable(functions, 'deleteInfoSession')(id);

  return response.data;
};

export const editInfoSession = async (editInfoSession: InfoSessionEditModel) => {
  try {
    if (!editInfoSession) {
      throw new Error();
    }
    const response: any = await httpsCallable(functions, 'editInfoSession')(editInfoSession);

    if (response.data.success) {
      toast.success('Info-Session Updated Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Updating a Info-Session!');
  }
};

export const getMultipleInfoSessionByIds = async (infoSessionIds: string[]) => {
  try {
    if (!infoSessionIds.length) {
      throw new Error();
    }

    const infoSessionsRef = collection(db, 'infoSessions');
    const querySnapshot = await getDocs(query(infoSessionsRef));
    const documents = querySnapshot.docs.filter((doc) => infoSessionIds.includes(doc.id));
    const infoSessionList: any = [];

    documents.forEach((doc) => {
      infoSessionList.push({ id: doc.id, ...doc.data() });
    });

    return infoSessionList;
  } catch (err: any) {
    toast.error('Error Retrieving Info Session Data!');
    return [];
  }
};

export const searchInfoSession = async (
  searchQuery: string,
  page: number,
  sortModel: ISortModel[],
  activeIds?: number[]
) => {
  try {
    const response: any = await httpsCallable(functions, 'searchInfoSession')({ query: searchQuery });

    const infoSessionData = response.data.data;
    const infoSessionIds: any = [];
    let infoSessionList = [];
    const currentDateTime = new Date();
    const fourteenDaysFromNow = new Date(currentDateTime.getTime() + 14 * 24 * 60 * 60 * 1000);
    fourteenDaysFromNow.setHours(23, 59, 59, 999);

    if (infoSessionData.length > 0) {
      for (const data of infoSessionData) {
        infoSessionIds.push(data.id);
      }

      infoSessionList = await getMultipleInfoSessionByIds(infoSessionIds);
      const upcomingSessions = infoSessionList?.filter((item: any) => {
        const itemDateTime = new Date(item.dateTime).toISOString();
        return itemDateTime > currentDateTime.toISOString() && itemDateTime <= fourteenDaysFromNow.toISOString();
      });
      const filteredInfoSessionList = activeIds
        ? getFilteredInfoSession(infoSessionList as InfoSessionEditModel[], activeIds || [])
        : [...upcomingSessions];

      if (sortModel.length > 0) {
        const field = sortModel[0].field;
        const sort = sortModel[0].sort;

        if (field === 'hra') {
          filteredInfoSessionList.sort((a: any, b: any) => {
            if (sort === 'asc') {
              return a.hra.localeCompare(b.hra);
            } else if (sort === 'desc') {
              return b.hra.localeCompare(a.hra);
            }
          });
        } else if (field === 'location') {
          filteredInfoSessionList.sort((a: any, b: any) => {
            if (!a.location && !b.location) {
              return 0;
            } else if (!a.location) {
              return 1;
            } else if (!b.location) {
              return -1;
            } else {
              if (sort === 'asc') {
                return a.location.localeCompare(b.location);
              } else if (sort === 'desc') {
                return b.location.localeCompare(a.location);
              }
            }
          });
        } else if (field === 'title') {
          filteredInfoSessionList.sort((a: any, b: any) => {
            if (sort === 'asc') {
              return a.title.localeCompare(b.title);
            } else if (sort === 'desc') {
              return b.title.localeCompare(a.title);
            }
          });
        } else if (field === 'dateTime') {
          filteredInfoSessionList.sort((a: InfoSessionEditModel, b: InfoSessionEditModel) => {
            const dateA = moment(a.dateTime).tz('America/New_York');
            const dateB = moment(b.dateTime).tz('America/New_York');

            if (sort === 'asc') {
              return dateA.valueOf() - dateB.valueOf();
            } else if (sort === 'desc') {
              return dateB.valueOf() - dateA.valueOf();
            }
            return 0;
          });
        } else if (field === 'sessionType') {
          filteredInfoSessionList.sort((a: any, b: any) => {
            if (sort === 'asc') {
              return a.sessionType.localeCompare(b.sessionType);
            } else if (sort === 'desc') {
              return b.sessionType.localeCompare(a.sessionType);
            }
          });
        } else if (field === 'numClients') {
          filteredInfoSessionList.sort((a: InfoSessionEditModel, b: InfoSessionEditModel) => {
            if (a.numClients === undefined && b.numClients === undefined) {
              return 0;
            } else if (a.numClients === undefined) {
              return 1;
            } else if (b.numClients === undefined) {
              return -1;
            } else {
              if (sort === 'asc') {
                return a.numClients - b.numClients;
              } else if (sort === 'desc') {
                return b.numClients - a.numClients;
              }
            }
            return 0;
          });
        }
      } else {
        filteredInfoSessionList.sort((a, b) => {
          const dateA = a.createdAt ? convertTimeStampToDate(a.createdAt).toDate() : null;
          const dateB = b.createdAt ? convertTimeStampToDate(b.createdAt).toDate() : null;

          if (dateA && dateB) {
            return dateB.getTime() - dateA.getTime();
          } else if (dateB) {
            return 1; // Place documents without 'createdAt' field after those with 'createdAt'
          } else if (dateA) {
            return -1; // Place documents without 'createdAt' field before those with 'createdAt'
          } else {
            return 0; // Both documents don't have 'createdAt' field, no preference in order
          }
        });
      }

      const pageSize = 10;
      const offset = (page - 1) * pageSize;
      const totalPages = Math.ceil(filteredInfoSessionList.length / pageSize);
      const paginatedInfoSessions = filteredInfoSessionList.slice(offset, offset + pageSize);

      const result = {
        totalRecords: filteredInfoSessionList.length,
        currentPage: page,
        infoSessions: paginatedInfoSessions,
        totalPages: totalPages,
      };
      return result;
    } else {
      return {
        totalRecords: 0,
        currentPage: page,
        infoSessions: [],
        totalPages: 0,
      };
    }
  } catch (error) {
    toast.error('Error Searching Info Session!');
  }
};

export const getInfoSessionById = async (infoSessionId: string, callback: Function) => {
  try {
    if (!infoSessionId) {
      throw new Error();
    }
    const colRef = doc(db, 'infoSessions', infoSessionId);
    const unsubscribe = onSnapshot(colRef, async (snapshot) => {
      if (snapshot.exists()) {
        const payload = { id: snapshot.id, ...snapshot.data() };
        callback(payload);
      } else {
        callback({});
      }
    });
    return unsubscribe;
  } catch (error: any) {
    toast.error('Error Retrieving Info Session!');
  }
};

function shouldAddToClientIds(data: any, infoSessionId: string, isSessionExists: boolean) {
  return (
    ((data.infoSessionIds && data.infoSessionIds.includes(infoSessionId)) ||
      (data.infoSessionId && data.infoSessionId === infoSessionId)) &&
    !isSessionExists
  );
}

export const fetchRegisteredClientsForInfoSession = async (infoSessionId: string, callback: Function) => {
  try {
    const collectionRef = collection(db, 'clients');
    const unsubscribe = onSnapshot(collectionRef, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const clientIds: string[] = [];
        querySnapshot.docs.forEach((doc: any) => {
          const data = doc.data();
          if (data.account !== userAccountStatus.DELETED) {
            const isSessionExists =
              data.infoSessionAttendance?.some((attendance: any) => attendance.infoSessionId === infoSessionId) ||
              false;
            if (shouldAddToClientIds(data, infoSessionId, isSessionExists)) {
              clientIds.push(doc.id);
            }
          }
        });
        const clientList = await getMultipleClientByIds(clientIds);
        callback(clientList);
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    throw new Error('Error While Fetching Client Data Registered in a Info-Session');
  }
};

export const markInfoSessionAttendance = async (clientIds: string[], infoSessionId: string, markedBy: string) => {
  try {
    const payload = {
      ids: clientIds,
      infoSessionId: infoSessionId,
      markedBy: markedBy,
    };
    const response = await httpsCallable(functions, 'markClientsInfoSessionAttendance')(payload);
    return response.data;
  } catch (error) {
    toast.error('Error While Marking Info-Session Attendance!');
  }
};

export const fetchClientsAttendedInfoSession = async (infoSessionId: string, callback: Function) => {
  try {
    const collectionRef = collection(db, 'clients');
    const unsubscribe = onSnapshot(collectionRef, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const clientIds: string[] = [];
        querySnapshot.docs.forEach((doc: any) => {
          const data = doc.data();
          const isSessionExists =
            data.infoSessionAttendance?.some((attendance: any) => attendance.infoSessionId === infoSessionId) || false;
          if (data.account !== userAccountStatus.DELETED && data.infoSessionAttendance) {
            if (data.infoSessionId || data.infoSessionIds.includes(infoSessionId) || isSessionExists) {
              clientIds.push(doc.id);
            }
          }
        });
        const clientList = await getMultipleClientByIds(clientIds);
        for (const client of clientList) {
          for (const item of client.infoSessionAttendance) {
            if (item.markedBy) {
              const userData: any = await getUserInfoById(item.markedBy);
              item.markedBy = userData.firstName + ' ' + userData.lastName;
            }
          }
        }
        callback(clientList);
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    throw new Error('Error While Fetching Client Data Attended a Info-Session');
  }
};

export const addPCsToAttendanceRoster = async (clientIds: string[], infoSessionId: string) => {
  try {
    const payload: IAddPCToAttendanceRoster = {
      ids: clientIds,
      infoSessionId: infoSessionId,
    };
    const response = await httpsCallable(functions, 'addPCsToAttendanceRoster')(payload);
    return response.data;
  } catch (error) {
    toast.error('Error While Adding Clients to Info-Session Attendance Roster!');
  }
};

export const sendTextMessageReminder = async (clientIds: string[], infoSessionId: string) => {
  try {
    const payload: IAddPCToAttendanceRoster = {
      ids: clientIds,
      infoSessionId: infoSessionId,
    };
    const response: any = await httpsCallable(functions, 'sendInfoSessionTextMsgReminder')(payload);
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Text Message Reminder!');
    }
  } catch (error) {
    toast.error('Error While Text Message Reminder!');
  }
};

export const fetchClientsToSendTextMsgReminder = async (infoSessionId: string, callback: Function) => {
  try {
    const collectionRef = collection(db, 'clients');
    const unsubscribe = onSnapshot(collectionRef, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const clientIds: string[] = [];
        querySnapshot.docs.forEach((doc: any) => {
          const data = doc.data();
          if (data.account !== userAccountStatus.DELETED) {
            const isSessionExists =
              data.infoSessionAttendance?.some((attendance: any) => attendance.infoSessionId === infoSessionId) ||
              false;
            if (shouldAddToClientIds(data, infoSessionId, isSessionExists)) {
              clientIds.push(doc.id);
            }
          }
        });
        callback(clientIds);
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    throw new Error('Error While Fetching Clients to send Reminder');
  }
};

export const isCustomUrlNameUnique = async (customUrlName: string): Promise<boolean> => {
  try {
    const colRef = collection(db, 'infoSessions');

    const q = query(colRef, where('customUrlName', '==', customUrlName));

    const querySnapshot = await getDocs(q);

    return querySnapshot.empty;
  } catch (error) {
    return false;
  }
};

export const getAnInfoSessionByCustomUrlName = async (customUrlName: string) => {
  try {
    const colRef = collection(db, 'infoSessions');
    const q = query(colRef, where('customUrlName', '==', customUrlName));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const data = { id: doc.id, ...doc.data() };
      return data;
    }
    return;
  } catch (error) {
    console.error('Error while fetching info session by custom URL name:', error);
    throw new Error('Error while fetching info session by custom URL name!');
  }
};

export const fetchInfoSessionsByCustomUrlName = async (customUrlName: string) => {
  try {
    if (!customUrlName) return [];
    const session1 = await getAnInfoSessionByCustomUrlName(customUrlName);
    const session2 = await getAnInfoSessionByCustomUrlName(`${customUrlName}-1`);
    const result = [session1];
    if (session2) {
      result.push(session2);
    }
    return result;
  } catch (error) {
    console.error('Error fetching info sessions by custom URL name:', error);
    return [];
  }
};
