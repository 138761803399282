import React from 'react';
import { Checkbox, FormControlLabel, Link, Typography } from '@mui/material';

interface IUserAgreementProps {
  isUserAgree: boolean;
  setIsUserAgree: Function;
  setShowAgreementWarning: Function;
  fullName: string;
  setIsAgreeToTextMessaging: Function;
  isAgreeToTextMessaging: boolean;
}

const UserAgreement = (props: IUserAgreementProps) => {
  const {
    isUserAgree,
    setIsUserAgree,
    setShowAgreementWarning,
    fullName,
    setIsAgreeToTextMessaging,
    isAgreeToTextMessaging,
  } = props;
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            size='small'
            color='info'
            checked={isUserAgree}
            sx={{ alignItems: 'center', alignSelf: 'flex-start', mr: 1, position: 'relative', bottom: 6 }}
            onChange={(e) => {
              setIsUserAgree(e.target.checked);
              setShowAgreementWarning(false);
            }}
          />
        }
        label={
          <Typography variant='body2'>
            By checking this box, you are confirming that you have read, understood, and agree to{' '}
            <Link
              onClick={() => {
                let url = '';
                if (process.env.REACT_APP_PROJECT_ID === 'nyatlas-test') {
                  url = 'https://pincctest.com/clientAgreement';
                } else if (process.env.REACT_APP_PROJECT_ID === 'nyatlas-portal') {
                  url = 'https://pincc.nyc/clientAgreement';
                }

                const applicantName = encodeURIComponent(fullName);
                const finalUrl = `${url}?applicantName=${applicantName}`;

                window.open(finalUrl, '_blank', 'noopener,noreferrer');
              }}
              target='_blank'
              rel='noopener noreferrer'>
              PINCC Applicant Authorization to Request and/or Release Information & Acknowledgements Agreement.
            </Link>
          </Typography>
        }
      />
      <FormControlLabel
        sx={{ mt: 2 }}
        control={
          <Checkbox
            size='small'
            color='info'
            checked={isAgreeToTextMessaging}
            sx={{ alignSelf: 'flex-start', mr: 1, position: 'relative', bottom: 6 }}
            onChange={(e) => {
              setIsAgreeToTextMessaging(e.target.checked);
            }}
          />
        }
        label={
          <Typography variant='body2'>
            I Agree to PINCC Text Messaging Terms of Service
            <ol>
              <li>PINCC Text Messaging Service provides updates and important information regarding PINCC programs.</li>
              <li>
                {`You can cancel the SMS service at any time. Just text "STOP" to the short code. After you send the SMS
                message "STOP" to us, we will send you an SMS message to confirm that you have been unsubscribed. After
                this, you will no longer receive SMS messages from us. If you want to join again, just sign up as you
                did the first time, and we will start sending SMS messages to you again.`}
              </li>
              <li>
                If you are experiencing issues with the messaging program, you can reply with the keyword HELP for more
                assistance, or you can get help directly at <a href='mailto:pincc@hra.nyc.gov'>pincc@hra.nyc.gov</a>.
              </li>
              <li>Carriers are not liable for delayed or undelivered messages.</li>
              <li>
                {`As always, message and data rates may apply for any messages sent to you from us and 
                to us from you. You will receive {message frequency}. If you have any questions about your 
                text plan or data plan, it is best to contact your wireless provider.`}
              </li>
              <li>If you have any questions regarding privacy, please read our privacy policy.</li>
            </ol>
          </Typography>
        }
      />
    </div>
  );
};

export default UserAgreement;
