import React, { useEffect } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Typography,
  Select,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from '@mui/material';
import {
  ReferralSourceConst,
  educations,
  employeeStatus,
  ethnics,
  genders,
  highestDL,
  interestedInOptions,
  publicAssistance,
  referralSource,
} from '../../pages/admin/client/utils';
import {
  ClientModel,
  addNoteOnInterestUpdate,
  getClientInfoById,
  updateClientInfo,
} from '../../services/client.service';
import TextField from '../../ReusableComponents/Textfield';
import { COLORS } from '../../utils/colors';
import * as yup from 'yup';
import { Field, Formik } from 'formik';
import { UserRolesEnum } from '../../utils/rolesEnum';
import { useAppSelector } from '../../store/store';
import { UserModel } from '../../store/user/userModel';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { convertTimeStampToDate } from '../../utils/dateTime';

export type ClientInfoFormProps = {
  client: ClientModel;
  onSubmit: Function;
  isProspectiveClientInfo?: boolean;
};

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  middleName: yup.string().optional(),
  lastName: yup.string().required('Last Name is required'),
  mobile: yup.string().max(50).required('Mobile Number is required'),
  homeNumber: yup.string().max(50),
  workNumber: yup.string().max(50),
  email: yup.string().required('Email is required'),
  gender: yup.string(),
  dob: yup.string(),
  address1: yup.string(),
  address2: yup.string(),
  state: yup.string(),
  city: yup.string(),
  zip: yup.string(),
  ssn: yup.string().max(4),
  ethnic: yup.string(),
  employmentStatus: yup.string(),
  educationalBackground: yup.string(),
  highestDL: yup.string(),
});

export const ClientInfoForm = (props: ClientInfoFormProps) => {
  const [client, setClient] = React.useState<ClientModel | undefined>(props.client);
  const beforeInterestedIn = client?.interestedIn || [];
  const [selectedPublicAssistance, setSelectedPublicAssistance] = React.useState<string[]>(
    client?.publicAssistance || []
  );
  const [selectedInterestedInFields, setSelectedInterestedInFields] = React.useState<string[]>(
    client?.interestedIn || []
  );
  const [selectedReferralSource, setSelectedReferralSource] = React.useState<string>(client?.referralSource || '');
  const user: UserModel = useAppSelector((state: any) => state.user.user) || {};
  const isValidUser: boolean =
    user.role == UserRolesEnum.ADMIN ||
    user.role === UserRolesEnum.SUPER_ADMIN ||
    user.role === UserRolesEnum.NAVIGATOR ||
    user.role === UserRolesEnum.EMPLOYMENT_LIAISON;

  const initialValues = {
    firstName: client?.firstName || '',
    middleName: client?.middleName || '',
    lastName: client?.lastName || '',
    mobile: client?.mobile || '',
    homeNumber: client?.homeNumber || '',
    workNumber: client?.workNumber || '',
    email: client?.email || '',
    gender: client?.gender || '',
    address1: client?.address1 || '',
    address2: client?.address2 || '',
    state: client?.state || '',
    city: client?.city || '',
    zip: client?.zip || '',
    dob: client?.dob || '',
    ssn: client?.ssn || '',
    ethnic: client?.ethnic || '',
    employmentStatus: client?.employmentStatus || '',
    educationalBackground: client?.educationalBackground || '',
    caseNumber: client?.caseNumber || '',
    publicAssistance: client?.publicAssistance || [],
    publicAssistanceOther: client?.publicAssistanceOther || '',
    referralSource: client?.referralSource || '',
    refCommunityOrg: client?.refCommunityOrg || '',
    interestedIn: client?.interestedIn || [],
    highestDL: client?.highestDL || '',
  };

  function arraysAreEqual(arr1: any[], arr2: any[]) {
    return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
  }

  const submitForm = async (values: any) => {
    if (values.referralSource && values.referralSource !== ReferralSourceConst.COMMUNITY_BASED_ORG) {
      values.refCommunityOrg = '';
    }
    const payload = {
      ...values,
      publicAssistance: selectedPublicAssistance,
      interestedIn: selectedInterestedInFields,
    };
    await updateClientInfo(payload, client?.id || '');

    if (values.interestedIn?.length > 0 && !arraysAreEqual(values.interestedIn, beforeInterestedIn)) {
      const interestNotePayload = {
        clientId: client?.id || '',
        title: 'Client Interest Updated',
        description: values.interestedIn.join(','),
        createdBy: user.id || '',
        role: user.role || '',
      };
      await addNoteOnInterestUpdate(interestNotePayload);
    }

    setTimeout(async () => {
      await getClientInfo();
    }, 2000);
    props.onSubmit();
  };

  const getClientInfo = async () => {
    const clientInfo: any = await getClientInfoById(client?.id || '');
    setClient(clientInfo);
  };

  useEffect(() => {
    (async () => {
      await getClientInfo();
    })();
    // eslint-disable-next-line
  }, []);

  const handleSelect = (item: string) => {
    if (selectedPublicAssistance?.includes(item)) {
      setSelectedPublicAssistance(selectedPublicAssistance?.filter((id: string) => id !== item));
    } else {
      setSelectedPublicAssistance([...selectedPublicAssistance, item]);
    }
  };

  const handleSelectForInterestedIn = (item: string) => {
    if (selectedInterestedInFields?.includes(item)) {
      setSelectedInterestedInFields(selectedInterestedInFields?.filter((id: string) => id !== item));
    } else {
      setSelectedInterestedInFields([...selectedInterestedInFields, item]);
    }
  };

  return (
    <Box>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submitForm} validateOnMount={true}>
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit, isSubmitting }: any) => (
          <form onSubmit={handleSubmit}>
            <>
              <Typography color={COLORS.palette.black} gutterBottom fontWeight='bold'>
                General Information:
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    label='First Name'
                    placeholder='First name'
                    fullWidth
                    name='firstName'
                    type='text'
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.firstName && Boolean(errors.firstName)}
                    disabled={!isValidUser}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    label='Middle Name'
                    placeholder='Middle name'
                    fullWidth
                    name='middleName'
                    type='text'
                    value={values.middleName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.middleName && Boolean(errors.middleName)}
                    disabled={!isValidUser}
                    helperText={touched.middleName && errors.middleName}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    label='Last Name'
                    placeholder='Last name'
                    fullWidth
                    name='lastName'
                    type='text'
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.lastName && Boolean(errors.lastName)}
                    disabled={!isValidUser}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    label='Mobile Number'
                    fullWidth
                    name='mobile'
                    placeholder='Phone Number'
                    value={values.mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.mobile && Boolean(errors.mobile)}
                    disabled={!isValidUser}
                    helperText={touched.mobile && errors.mobile}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    label='Email'
                    fullWidth
                    name='email'
                    type='email'
                    placeholder='Email'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    disabled={!isValidUser}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    label='Home Number'
                    fullWidth
                    name='homeNumber'
                    placeholder='Home Number'
                    value={values.homeNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.homeNumber && Boolean(errors.homeNumber)}
                    disabled={!isValidUser}
                    helperText={touched.homeNumber && errors.homeNumber}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    label='Work Number'
                    fullWidth
                    name='workNumber'
                    placeholder='Work Number'
                    value={values.workNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.workNumber && Boolean(errors.workNumber)}
                    disabled={!isValidUser}
                    helperText={touched.workNumber && errors.workNumber}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <InputLabel style={localStyle.labelStyle} id='gender-label'>
                    Gender
                  </InputLabel>
                  <FormControl fullWidth>
                    <Field
                      as={Select}
                      labelId='gender-label'
                      size='small'
                      name='gender'
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.gender && Boolean(errors.gender)}
                      disabled={!isValidUser}
                      helperText={touched.gender && errors.gender}>
                      {genders.map((option) => (
                        <MenuItem key={option.id} value={option.title}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Field
                    name='dob'
                    component={DatePicker}
                    label='Date Of Birth'
                    inputFormat='MM/DD/YYYY'
                    value={dayjs(values.dob)}
                    onBlur={handleBlur}
                    onChange={(dob: string | number | Date | dayjs.Dayjs | null | undefined) => {
                      const formattedDate = dayjs(dob).format('MM/DD/YYYY');
                      setFieldValue('dob', formattedDate);
                    }}
                    sx={localStyle.dobFielStyle}
                    error={touched.dob && Boolean(errors.dob)}
                    disabled={!isValidUser}
                    helperText={touched.dob && errors.dob}
                  />
                  {touched.dob && Boolean(errors.dob) ? (
                    <p style={{ color: COLORS.result.error }}>{errors.dob}</p>
                  ) : null}
                </Grid>
              </Grid>
              <Box mt={3}>
                <Typography color={COLORS.palette.black} gutterBottom fontWeight='bold'>
                  Address Information:
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={4}>
                    <TextField
                      label='Address Line 1'
                      placeholder='Address line 1'
                      fullWidth
                      name='address1'
                      type='text'
                      value={values.address1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.address1 && Boolean(errors.address1)}
                      disabled={!isValidUser}
                      helperText={touched.address1 && errors.address1}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <TextField
                      label='Address Line 2'
                      placeholder='Address line 2'
                      fullWidth
                      name='address2'
                      type='text'
                      value={values.address2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.address2 && Boolean(errors.address2)}
                      disabled={!isValidUser}
                      helperText={touched.address2 && errors.address2}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <TextField
                      label='State'
                      placeholder='State'
                      fullWidth
                      name='state'
                      type='text'
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.state && Boolean(errors.state)}
                      disabled={!isValidUser}
                      helperText={touched.state && errors.state}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <TextField
                      label='City'
                      placeholder='City'
                      fullWidth
                      name='city'
                      type='city'
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.city && Boolean(errors.city)}
                      disabled={!isValidUser}
                      helperText={touched.city && errors.city}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <TextField
                      label='ZIP'
                      placeholder='ZIP'
                      fullWidth
                      name='zip'
                      type='text'
                      value={values.zip}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.zip && Boolean(errors.zip)}
                      disabled={!isValidUser}
                      helperText={touched.zip && errors.zip}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <TextField
                      label='Last 4 SSN#'
                      placeholder='Last 4 SSN#'
                      fullWidth
                      name='ssn'
                      type='text'
                      value={values.ssn}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.ssn && Boolean(errors.ssn)}
                      disabled={!isValidUser}
                      helperText={touched.ssn && errors.ssn}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3}>
                <Typography color={COLORS.palette.black} gutterBottom fontWeight='bold'>
                  Other Information:
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={4}>
                    <InputLabel style={localStyle.labelStyle} id='ethnic-label'>
                      Race/Ethnic Identity
                    </InputLabel>
                    <FormControl fullWidth>
                      <Field
                        as={Select}
                        labelId='ethnic-label'
                        name='ethnic'
                        size='small'
                        value={values.ethnic}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.ethnic && Boolean(errors.ethnic)}
                        disabled={!isValidUser}
                        helperText={touched.ethnic && errors.ethnic}>
                        {ethnics.map((option) => (
                          <MenuItem key={option.id} value={option.title}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} lg={4}>
                    <InputLabel style={localStyle.labelStyle} id='employmentStatus-label'>
                      Employment Status
                    </InputLabel>
                    <FormControl fullWidth>
                      <Field
                        as={Select}
                        labelId='employmentStatus-label'
                        name='employmentStatus'
                        size='small'
                        value={values.employmentStatus}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.employmentStatus && Boolean(errors.employmentStatus)}
                        disabled={!isValidUser}
                        helperText={touched.employmentStatus && errors.employmentStatus}>
                        {employeeStatus.map((option) => (
                          <MenuItem key={option.id} value={option.title}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} lg={4}>
                    <InputLabel style={localStyle.labelStyle} id='education-label'>
                      Educational Background
                    </InputLabel>
                    <FormControl fullWidth>
                      <Field
                        as={Select}
                        labelId='education-label'
                        name='educationalBackground'
                        size='small'
                        value={values.educationalBackground}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.educationalBackground && Boolean(errors.educationalBackground)}
                        disabled={!isValidUser}
                        helperText={touched.educationalBackground && errors.educationalBackground}>
                        {educations.map((option) => (
                          <MenuItem key={option.id} value={option.title}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <InputLabel style={localStyle.labelStyle} id='education-label'>
                      {`Highest Driver's license obtained`}
                    </InputLabel>
                    <FormControl fullWidth>
                      <Field
                        as={Select}
                        name='highestDL'
                        size='small'
                        value={values.highestDL}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.highestDL && Boolean(errors.highestDL)}
                        helperText={touched.highestDL && errors.highestDL}>
                        {highestDL.map((option) => (
                          <MenuItem key={option.id} value={option.title}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>

                  {selectedReferralSource ===
                  referralSource.find((i) => i.id === ReferralSourceConst.COMMUNITY_BASED_ORG_ID)?.title ? (
                    <Grid item xs={12} lg={4}>
                      <TextField
                        label='Organization Name'
                        fullWidth
                        placeholder='Organization Name'
                        name='refCommunityOrg'
                        value={values.refCommunityOrg}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.refCommunityOrg && Boolean(errors.refCommunityOrg)}
                        helperText={touched.refCommunityOrg && errors.refCommunityOrg}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={4} lg={4}>
                    <TextField
                      label='Case Number'
                      placeholder='Case Number'
                      fullWidth
                      name='caseNumber'
                      type='text'
                      value={values.caseNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.caseNumber && Boolean(errors.caseNumber)}
                      disabled={!isValidUser}
                      helperText={touched.caseNumber && errors.caseNumber}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <InputLabel style={localStyle.labelStyle} id='referral-source'>
                      Referral Source
                    </InputLabel>
                    <FormControl fullWidth>
                      <Field
                        as={Select}
                        labelId='referral-source'
                        name='referralSource'
                        size='small'
                        value={values.referralSource}
                        onChange={(event: any) => {
                          handleChange(event);
                          setSelectedReferralSource(event.target.value);
                        }}
                        onBlur={handleBlur}
                        error={touched.referralSource && Boolean(errors.referralSource)}
                        disabled={!isValidUser}
                        helperText={touched.referralSource && errors.referralSource}>
                        {referralSource.map((option) => (
                          <MenuItem key={option.id} value={option.title}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Field>
                      {client?.refSourceEntryDate ? (
                        <Box mt={2} sx={localStyle.sourceDateStyle}>
                          <h5 style={{ marginRight: '10px' }}>{'Source Entry Date:'}</h5>
                          <p>{convertTimeStampToDate(client?.refSourceEntryDate).format('MMMM DD, YYYY')}</p>
                        </Box>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <FormControl>
                      <FormLabel id='demo-row-radio-buttons-group-label'>Household Supports and Income</FormLabel>
                      <List>
                        {publicAssistance.map((item: any) => (
                          <ListItem key={item.id} disablePadding>
                            <Checkbox
                              onChange={() => {
                                handleSelect(item.title);
                              }}
                              checked={selectedPublicAssistance.includes(item.title)}
                            />
                            <ListItemText primary={item.title} />
                          </ListItem>
                        ))}
                      </List>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <FormControl>
                      <FormLabel id='demo-row-radio-buttons-group-label'>InterestedIn</FormLabel>
                      <List>
                        {interestedInOptions.map((item: any) => (
                          <ListItem key={item.id} disablePadding>
                            <Checkbox
                              onChange={() => {
                                handleSelectForInterestedIn(item.title);
                              }}
                              checked={selectedInterestedInFields.includes(item.title)}
                            />
                            <ListItemText primary={item.title} />
                          </ListItem>
                        ))}
                      </List>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              {isValidUser ? (
                <Box display='flex' justifyContent='center' alignItems='center' mt={5}>
                  <LoadingButton
                    label={'Submit'}
                    loading={isSubmitting}
                    styles={{ width: '350px' }}
                    disabled={isSubmitting}
                    size='medium'
                    type='submit'
                    variant='contained'
                  />
                </Box>
              ) : null}
            </>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const localStyle = {
  labelStyle: {
    color: COLORS.palette.black,
    fontSize: '12px',
    marginBottom: '4px',
  },
  sourceDateStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  dobFielStyle: {
    width: '100%',
    marginTop: '20px',
  },
};
